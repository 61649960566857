<template>
  <v-row v-if="genderIcon">
    <v-col
      cols="auto"
    >
      <v-icon
        v-if="genderIcon"
        :color="genderIcon.color"
      >
        {{ genderIcon.icon }}
      </v-icon>
      <span v-else>-</span>
    </v-col>
  </v-row>
</template>

  <script>

  export default {
    name: 'GenderColumn',

    props: {
      value: {
        type: String,
        default: null,
      },
    },
    computed: {
      genderIcon () {
        if (!this.value) return null

        return {
          m: { icon: 'mdi-gender-male', color: 'primary' },
          f: { icon: 'mdi-gender-female', color: 'pink' },
        }[this.value]
      },
    },
    methods: {},
  }
</script>
<style lang="scss" scoped>

</style>
